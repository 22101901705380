import {
    Button, Grid, TextField, Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../AllClients/AllClients.css";
import Iconify from "../iconify";
import { FileUpload, getData, patchData, postData } from "src/webService/webService";
import { toast, ToastContainer } from "react-toastify";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import { useDispatch, useSelector } from "react-redux";
import { setALLCLIENTSLIST } from "src/redux/Slices/AllClientSlices";
import FILEROUTES from "src/API_Routes/File/file";
import accept from '../logo/accept.png';
import logo from '../logo/usf_logo.jpeg';

function ClientFields({
    HandleAddClient,
    action,
    AllClients,
    currentPage,
    limit,
    setTotalItems,
}) {
    const accessToken = sessionStorage.getItem("Token");

    const [clientDetails, setClientDetails] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [unit, setUnit] = useState("");
    const [phoneNumber, SetPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [doorNo, setDoorNo] = useState("");
    const [streetName, setStreetName] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [gstResponse, setGstResponse] = useState(null);
    const [msmeResponse, setMsmeResponse] = useState(null);
    const [incorporationResponse, setIncorporationResponse] = useState(null);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [errorGst, setErrorGst] = useState(false);
    const [helperTextGst, setHelperTextGst] = useState("");
    const [errorPincode, setErrorPincode] = useState(false);
    const [helperTextPincode, setHelperTextPincode] = useState("");
    const [errorMail, setErrorMail] = useState(false);
    const [helperTextMail, setHelperTextMail] = useState("");
    const [open, setOpen] = useState(false);
    const URL = "https://api.usf.digitaltriumph.co.in/";
    const specificClientsList = useSelector(
        (state) => state?.ALLCLIENTSLIST?.specificClientsList
    );
    console.log(specificClientsList);

    //GST validation
    const validateGstNumber = (gstNumber) => {
        const gstRegex =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
        return gstRegex.test(gstNumber);
    };

    const handleGstNumberChange = (e) => {
        const value = e.target.value.toUpperCase(); // Convert to uppercase
        if (value.length <= 15) {
            setGstNo(value);
            if (validateGstNumber(value)) {
                setErrorGst(false);
                setHelperTextGst("");
            } else {
                setErrorGst(true);
                setHelperTextGst("Please enter a valid 15-character GST number");
            }
        }
    };
    //PhoneNumber validation
    const validatePhoneNumber = (number) => {
        if (number === "") {
            return true; // No error if the input is empty
        }
        const phoneNumberPattern = /^[0-9]{10}$/;
        return phoneNumberPattern.test(number);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            SetPhoneNumber(value);
            if (validatePhoneNumber(value)) {
                setError(false);
                setHelperText("");
            } else {
                setError(true);
                setHelperText("Please enter a valid 10-digit mobile number");
            }
        }
    };

    //Pincode Validation
    const validatePinCode = (pinCode) => {
        const pinCodeRegex = /^[0-9]{6}$/;
        return pinCodeRegex.test(pinCode);
    };

    const handlePinCodeChange = (e) => {
        const value = e.target.value;
        setPinCode(value);
        if (validatePinCode(value) || value === "") {
            setErrorPincode(false);
            setHelperTextPincode("");
        } else {
            setErrorPincode(true);
            setHelperTextPincode("Pin Code must be exactly 6 digits");
        }
    };

    //Mail Validation
    const validateEmail = (email) => {
        if (email === "") {
            return true; // No error if the input is empty
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        if (validateEmail(value)) {
            setErrorMail(false);
            setHelperTextMail("");
        } else {
            setErrorMail(true);
            setHelperTextMail("Please enter a valid email address");
        }
        setEmail(value);
    };

    const handleFileUpload = async (event, type) => {
        debugger;
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const uploadRes = await FileUpload(
            `${FILEROUTES.UPLOAD}${type}`,
            formData,
            dispatch
        );

        if (type === "1") {
            setGstResponse(uploadRes.data); // Store GST response
        } else if (type === "2") {
            setMsmeResponse(uploadRes.data); // Store MSME response
        } else if (type === "3") {
            setIncorporationResponse(uploadRes.data); // Store Incorporation Certificate response
        }

        console.log(uploadRes.data);
    };

    const AllClientsList = async () => {
        const params = {
            page: currentPage,
            limit: limit,
        };
        try {
            const res = await getData(CLIENTAPI.GETCLIENTS, params, accessToken);
            if (res.status === 200) {
                if (res?.data?.totalItems) {
                    setTotalItems(res.data.totalItems);
                } else {
                    setTotalItems(0);
                }
                dispatch(setALLCLIENTSLIST(res.data));
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        // const fields = [
        //   {
        //     name: "companyName",
        //     value: companyName,
        //     error: "Company Name is required",
        //   },
        //   { name: "unit", value: unit, error: "Unit is required" },
        //   { name: "doorNo", value: doorNo, error: "Door No is required" },
        //   {
        //     name: "streetName",
        //     value: streetName,
        //     error: "Street Name is required",
        //   },
        //   { name: "city", value: city, error: "City is required" },
        //   {
        //     name: "pinCode",
        //     value: validatePinCode(pinCode),
        //     error: "Pin Code must be exactly 6 digits",
        //   },
        //   {
        //     name: "email",
        //     value: validateEmail(email),
        //     error: "Please enter a valid email address",
        //   },
        //   {
        //     name: "phoneNumber",
        //     value: validatePhoneNumber(phoneNumber),
        //     error: "Please Enter A Valid 10-digit Mobile Number",
        //   },
        //   {
        //     name: "gstNo",
        //     value: validateGstNumber(gstNo),
        //     error: "Please Enter A Valid 15-character GST number",
        //   },
        // ];

        // for (const field of fields) {
        //   switch (true) {
        //     case !field.value:
        //       toast.error(field.error);
        //       return;
        //     default:
        //       break;
        //   }
        // }

        const fields = [
            {
                name: "companyName",
                value: companyName.trim(),
                error: "Company Name is required",
            },
            { name: "unit", value: unit.trim(), error: "Unit is required" },
            { name: "doorNo", value: doorNo.trim(), error: "Door No is required" },
            {
                name: "streetName",
                value: streetName.trim(),
                error: "Street Name is required",
            },
            { name: "city", value: city.trim(), error: "City is required" },
            {
                name: "pinCode",
                value: validatePinCode(pinCode),
                error: "Pin Code must be exactly 6 digits",
            },
            {
                name: "email",
                value: email,
                error: "Please enter a valid email address",
                validate: (value) => value === "" || validateEmail(value),
            },
            {
                name: "phoneNumber",
                value: phoneNumber,
                error: "Please Enter A Valid 10-digit Mobile Number",
                validate: (value) => value === "" || validatePhoneNumber(value),
            },
            {
                name: "gstNo",
                value: validateGstNumber(gstNo),
                error: "Please Enter A Valid 15-character GST number",
            },
        ];

        for (const field of fields) {
            if (
                !field.value &&
                field.name !== "email" &&
                field.name !== "phoneNumber"
            ) {
                toast.error(field.error);
                return;
            }
            if (
                (field.name === "email" || field.name === "phoneNumber") &&
                !field.validate(field.value)
            ) {
                toast.error(field.error);
                return;
            }
        }

        const params = {
            companyname: companyName.trim(),
            branch: unit.trim(),
            phoneNumber: phoneNumber,
            email: email,
            doorNo: doorNo.trim(),
            streetName: streetName,
            city: city.trim(),
            pinCode: pinCode,
            gstNo: gstNo,
            gstFileObject: gstResponse,
            msmeFileObject: msmeResponse,
            incorporateCerticateFileObject: incorporationResponse,
        };

        debugger;

        if (action === "Edit") {
            patchData(
                `clients/client/${specificClientsList._id}`,
                params,
                accessToken
            )
                .then((res) => {
                    setClientDetails(res.data);
                    HandleAddClient();
                    AllClientsList();
                    toast.success("Customer updated successfully");
                    console.log(clientDetails);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            postData("clients/saveclient", params)
                .then((res) => {
                    setClientDetails(res.data);
                    HandleAddClient();
                    AllClientsList();
                    // toast.success("Customer added successfully");
                    console.log(clientDetails);
                    handleClickOpen();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
        setOpen(true);
        setCompanyName("");
        setUnit("");
        SetPhoneNumber("");
        setEmail("");
        setDoorNo("");
        setStreetName("");
        setCity("");
        setPinCode("");
        setGstNo("");
        setGstResponse("");
        setMsmeResponse("");
        setIncorporationResponse("");
    };

    useEffect(() => {
        if (action === "Edit" || action === "View") {
            if (specificClientsList) {
                setCompanyName(specificClientsList?.companyname);
                setUnit(specificClientsList?.branch);
                SetPhoneNumber(specificClientsList?.phoneNumber);
                setEmail(specificClientsList?.email);
                setDoorNo(specificClientsList?.doorNo);
                setStreetName(specificClientsList?.streetName);
                setCity(specificClientsList?.city);
                setPinCode(specificClientsList?.pinCode);
                setGstNo(specificClientsList?.gstNo);
            }
        } else {
            setCompanyName("");
            setUnit("");
            SetPhoneNumber("");
            setEmail("");
            setDoorNo("");
            setStreetName("");
            setCity("");
            setPinCode("");
            setGstNo("");
        }
    }, [action, specificClientsList]);

    return (
        <div className="p-6 card">
            <ToastContainer position="top-right" />
            <div className="d-flex justify-content-center align-items-center">
                <img src={logo} width="10%"/>
            </div>
            <div className="component-title pt-3">
                <h5>Add Customer</h5>
            </div>
            <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
                <div className="d-flex justify-content-center align-items-center pt-3 mb-3">
                    <img src={accept} width="30%" />
                </div>
                <DialogTitle className="text-center">Thank You for filling the details</DialogTitle>
                <DialogContent className="text-center">We'll contact you soon!</DialogContent>
            </Dialog>
            <div className="mb-5">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            label="Company Name *"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            label="Unit *"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={doorNo}
                            onChange={(e) => setDoorNo(e.target.value)}
                            label="Door No *"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="mb-5">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={streetName}
                            onChange={(e) => setStreetName(e.target.value)}
                            label="Street *"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            label="City *"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={pinCode}
                            onChange={handlePinCodeChange}
                            label="Pin Code *"
                            variant="outlined"
                            inputProps={{ maxLength: 6 }}
                            error={errorPincode}
                            helperText={helperTextPincode}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="mb-5">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={email}
                            onChange={handleEmailChange}
                            label="Email Address"
                            variant="outlined"
                            error={errorMail}
                            helperText={helperTextMail}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            type="number"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            label="Mobile No"
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                            error={error}
                            helperText={helperText}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            className="w-100"
                            id="outlined-basic"
                            value={gstNo}
                            onChange={handleGstNumberChange}
                            label="GST No *"
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                            error={errorGst}
                            helperText={helperTextGst}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: action === "View",
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
            {action !== "View" && (
                <div className="mb-5">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <div
                                className="btn-primary"
                                style={{ borderRadius: "6px" }}
                            >
                                <label
                                    className="d-flex justify-content-between align-items-center"
                                    htmlFor="gst-upload"
                                    style={{
                                        cursor: "pointer",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: '4px',
                                    }}
                                >
                                    <p className="mb-0">GST Document</p>
                                    <p className="mb-0">+</p>
                                </label>
                                <input
                                    id="gst-upload"
                                    style={{ display: "none" }}
                                    type="file"
                                    InputProps={{
                                        readOnly: action === "View",
                                    }}
                                    onChange={(e) => handleFileUpload(e, "1")}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div
                                className="btn-primary"
                                style={{ borderRadius: "6px" }}
                            >
                                <label
                                    className="d-flex justify-content-between align-items-center"
                                    htmlFor="msme-upload"
                                    style={{
                                        cursor: "pointer",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: '4px',
                                    }}
                                >
                                    <p className="mb-0">MSME Document</p>
                                    <p className="mb-0">+</p>
                                </label>
                                <input
                                    id="msme-upload"
                                    style={{ display: "none" }}
                                    type="file"
                                    InputProps={{
                                        readOnly: action === "View",
                                    }}
                                    onChange={(e) => handleFileUpload(e, "2")}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div
                                className="btn-primary"
                                style={{ borderRadius: "6px" }}
                            >
                                <label
                                    className="d-flex justify-content-between align-items-center"
                                    htmlFor="incorporation-upload"
                                    style={{
                                        cursor: "pointer",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: '4px',
                                    }}
                                >
                                    <p className="mb-0">Incorporation Certificate</p>
                                    <p className="mb-0">+</p>
                                </label>
                                <input
                                    id="incorporation-upload"
                                    style={{ display: "none" }}
                                    type="file"
                                    InputProps={{
                                        readOnly: action === "View",
                                    }}
                                    onChange={(e) => handleFileUpload(e, "3")}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}

            <div className="d-flex align-items-center">
                <p className="mb-0">GST Document:</p>
                <a
                    style={{ marginLeft: "12px", textDecoration: "none" }}
                    href={`${URL}/${gstResponse?.uri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {gstResponse?.originalname}
                </a>
            </div>

            <div className="d-flex align-items-center">
                <p className="mb-0">MSME Document:</p>
                <a
                    style={{ marginLeft: "12px", textDecoration: "none" }}
                    href={`${URL}/${msmeResponse?.uri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {msmeResponse?.originalname}
                </a>
            </div>

            <div className="d-flex align-items-center">
                <p className="mb-0">Incorporation Certificate:</p>
                <a
                    style={{ marginLeft: "12px", textDecoration: "none" }}
                    href={`${URL}/${incorporationResponse?.uri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {incorporationResponse?.originalname}
                </a>
            </div>

            <div>
                <div className="d-flex justify-content-end mt-5">
                    <>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            className="ml-8 btn-primary"
                        >
                            ADD CUSTOMER
                        </Button>
                    </>
                </div>
            </div>
        </div>
    );
}
export default ClientFields;