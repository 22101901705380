import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import navConfigBiller from './configBiller';
import avatarLogo from "../../../components/logo/profile (1).png";
import Iconify from "../../../components/iconify";
import { useDispatch } from "react-redux";
import { FileUpload, getData, patchData } from 'src/webService/webService';
import FILEROUTES from 'src/API_Routes/File/file';


// ----------------------------------------------------------------------

const NAV_WIDTH = 250;
const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};



export default function Nav({ openNav, onCloseNav }) {
  const accessToken = sessionStorage.getItem("Token");
  const { pathname } = useLocation();
  const [lutCertificate, setLutCertificate] = useState();
  const URL = "https://api.usf.digitaltriumph.co.in/";
  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const userName = sessionStorage.getItem("UserName");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated")
  const userId = sessionStorage.getItem("UserId");
  // const lutCertificatefile = sessionStorage.getItem("lutdocument");

  // console.log(lutCertificatefile, "hishdifhsidfhoisfhisdhfiosdh");
  const [lutCertificatefile, setLutCertificatefile] = useState("");

  useEffect(() => {
    // Function to fetch the latest value from sessionStorage
    const fetchLutFile = () => {
      const storedFile = sessionStorage.getItem("lutdocument");
      setLutCertificatefile(storedFile || ""); // Default to an empty string if null
    };

    // Fetch the initial value on mount
    fetchLutFile();

    // Poll sessionStorage periodically to check for updates
    const intervalId = setInterval(fetchLutFile, 1000); // Adjust interval as needed

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  console.log(userName);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const handleFileUpload = async (event) => {
    // debugger;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadRes = await FileUpload(
      `${FILEROUTES.UPLOAD}${'InvoiceFile'}`,
      formData,
      dispatch
    );

    // setInvoiceFileObject(uploadRes.data);
    console.log(uploadRes.data);

    const params = {
      lutCertificateFileObject: uploadRes.data
    }
    try {
      const res = await patchData(
        `users/user/${userId}`,
        params,
        accessToken
      );
      if (res.status === 200) {
        console.log(res.data, "ishfishdifhsifhidhfsio");
        setLutCertificate(res.data)
        sessionStorage.setItem("lutdocument", res?.data?.lutCertificateFileObject?.uri)
        // console.log(ALLCLIENTSLIST, "Allclientslist")
      }
    } catch (error) {
      console.error("Error:", error);
    }

  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', background: '#fff' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar alt="photoURL" >
              <img src={avatarLogo} width="100%" />
            </Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userName.toUpperCase()}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {isAuthenticated}
              </Typography>
            </Box>

            {/* <div className='d-flex justify-content-end w-100'>
              <div>
                <label
                  htmlFor="file-upload"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <Iconify icon={"eva:edit-fill"} />
                </label>
                <input
                  id="file-upload"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleFileUpload}
                />
              </div>

              <a
                style={{ marginLeft: "12px", textDecoration: "none", color: "black" }}
                href={`${URL}/${lutCertificatefile}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Iconify icon={"tdesign:file-filled"} />
              </a>
            </div> */}
          </StyledAccount>
        </Link>
      </Box>
      {isAuthenticated === "Admin" ? (
        <NavSection data={navConfig} />

      ) : (
        <NavSection data={navConfigBiller} />
      )}

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
