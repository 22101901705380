import React from "react";
import logo from "../logo/usf_logo.jpeg";


const QualityReportSpl = ({ data }) => {
  const Name = sessionStorage.getItem("UserName");
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = `${dd}/${mm}/${yyyy}`;

  const formatProcessTime = (processTime) => {
    // Split the time string by ':'
    const [hours, minutes, seconds] = processTime.split(":");

    // Convert hours and minutes to numbers
    const h = parseInt(hours, 10);
    const m = parseInt(minutes, 10);

    // Conditional formatting
    if (h === 0) {
      // If no hours, just show minutes
      return `${m}mins`;
    } else {
      // If there are hours, show hours and minutes
      return `${h}hr ${m}mins`;
    }
  };


  return (
    <>
      <div style={{width:'99%'}}>
        <style>
          {`

          table, th, td {
            border: 0.3px solid #272727;
            border-collapse: collapse;
            padding: 6px !important;
            font-size: 10px !important;
            width:100%;
          }
            
        

          td {
            vertical-align: middle;
          }

          .bt {
            border-top: none !important;
          }

          .bb {
            border-bottom: none !important;
          }

          .bl {
            border-left: none !important;
          }

          .br {
            border-right: none !important;
          }

          .remarks-cell {
            white-space: normal !important;
            word-wrap: break-word;
            overflow-wrap: break-word;
          }
        `}
        </style>
        {data?.map((item, index) => (
          <div>
            <table>
              <tr className="w-100">
                <td
                  style={{ width: "15%" }}
                  rowspan="2"
                  className="br bb bl bt"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} width="70%" />
                  </div>
                </td>
                <td style={{ width: "60%" }} className="bb bt">
                  <p className="text-center mb-0">
                    <span className="fw-bold text-uppercase">
                      Final inspection quality report
                    </span>
                    <br />
                    <span>(In accordance with ISO 9001 : 2015)</span>
                  </p>
                </td>
                <td
                  style={{ width: "30%" }}
                  rowspan="2"
                  className="br bb bl bt"
                >
                  Date : {formattedToday}
                </td>
              </tr>
              <tr>
                <td
                  style={{ width: "60%" }}
                  className="text-center text-uppercase bb"
                >
                  Quality Report
                </td>
              </tr>
            </table>

            <div className="my-1 d-flex flex-wrap justify-content-between w-100">
              <table style={{ width: "49%" }}>
                <tr className="w-100">
                  <td className="fw-bold w-50 br bb bl bt">CUSTOMER NAME</td>
                  <td className="fw-bold w-50 br bb bt">
                    USUI SUSIRA <br />
                    INTERNATIONAL PVT.LTD
                  </td>
                </tr>
                <tr className="w-100">
                  <td className="bl bb br">PART NAME</td>
                  <td className="w-50 bb br">{item.partName}</td>
                </tr>
                <tr className="w-100">
                  <td className="w-50 bl bb br">PART NO</td>
                  <td className="w-50 bb br">{item.partNO}</td>
                </tr>
                <tr className="w-100">
                  <td className="w-50 bl bb br">PROCESS DATE</td>
                  <td className="w-50 bb br">{item.processDate.split("T")[0]}</td>
                </tr>
                <tr className="w-100">
                  <td className="w-50 bl bb br">QUANTITY (NOS)</td>
                  <td className="w-50 bb br">{item.Quantity} ({item.units})</td>
                </tr>
                <tr className="w-100">
                  <td className="w-50 bl bb br">USF DC NO</td>
                  <td className="w-50 bb br">{item.ourDcNo}</td>
                </tr>
                <tr className="w-100">
                  <td className="w-50 bl bb br">PROCESS</td>
                  <td className="w-50 bb br">{item.description}</td>
                </tr>
              </table>

              <table style={{ width: "49%" }}>
                <tr className="w-100">
                  <td className="fw-bold bt bl bb br" colSpan={3}>
                    VISUAL INSPECTION PARAMETERS
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase fw-bold bl bb br" style={{ width: "60%" }}>
                    PARAMETERS
                  </td>
                  <td className="fw-bold bb br" style={{ width: "20%" }}>
                    YES
                  </td>
                  <td className="fw-bold bb br" style={{ width: "20%" }}>
                    NO
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase bl bb br" style={{ width: "60%" }}>
                    SOOT REMOVAL
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.sootRemoval === "yes" ? "YES" : "--"}
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.sootRemoval !== "yes" ? "NO" : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase bl bb br" style={{ width: "60%" }}>
                    FINE FINISH
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.fineFinish === "yes" ? "YES" : "--"}
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.fineFinish !== "yes" ? "NO" : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase bl bb br" style={{ width: "60%" }}>
                    BURR REMOVAL
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.burrRemoval === "yes" ? "YES" : "--"}
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.burrRemoval !== "yes" ? "NO" : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase bl bb br" style={{ width: "60%" }}>
                    R.P OIL APPLICATION
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.rpOilApplication === "yes" ? "YES" : "--"}
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.rpOilApplication !== "yes" ? "NO" : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase bl bb br" style={{ width: "60%" }}>
                    PACKING (TRAYS)
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.packing === "yes" ? "YES" : "--"}
                  </td>
                  <td style={{ width: "20%" }} className="bb br">
                    {item.packing !== "yes" ? "NO" : "--"}
                  </td>
                </tr>
              </table>
            </div>

            <p className="fw-bold mb-1">OTHER DETAILS</p>
            <table className="w-100">
              <tr className="w-100">
                <td className="w-50 bt bl bb br">1. PROCESS TIME</td>
                <td className="w-50 bt bb br">{formatProcessTime(item.processTime)}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">2. BATCH QUANTITY</td>
                <td className="w-50 bb br">{item.BatchQuantity}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">3. SHOTS SIZE</td>
                <td className="w-50 bb br">{item.ShotsSize}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">4. COVERAGE AREA</td>
                <td className="w-50 bb br">{item.CoverageArea}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">5. AMMETER READING</td>
                <td className="w-50 bb br">{item.AmmeterReading}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">6. FIXED ROTATION</td>
                <td className="w-50 bb br">{item.FixedRotation}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">7. SHOTS SEIVING FREQUENCY</td>
                <td className="w-50 bb br">{item.ShotsSeivingFrequency}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">8. SHOTS HARDNESS</td>
                <td className="w-50 bb br">{item.ShotsHardness}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">9. ALMEN INTENSITY</td>
                <td className="w-50 bb br">{item.AlmenIntensity}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">10. STEM RZ VALUE</td>
                <td className="w-50 bb br">{item.StemRZValue}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">11. BALL RZ VALUE</td>
                <td className="w-50 bb br">{item.BallRZValue}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">12. CUP RZ VALUE</td>
                <td className="w-50 bb br">{item.CupRZValue}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">13. DW 902 R.P OIL</td>
                <td className="w-50 br">{item.DW902RPOIL}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">14. REMARKS</td>
                <td className="w-50 br remarks-cell">{item.remarks}</td>
              </tr>
              <tr className="w-100">
                <td className="w-50 bl bb br">INSPECTED BY : {item.inspectedBy}</td>
                <td className="w-50 bb br">INSPECTED DATE : {formattedToday}</td>
              </tr>
              
            </table>

            <p className="text-end" style={{marginTop: "80px", marginBottom: "35px", fontSize: "12px"}}>FOR ULTRA SURFACE FINISHERS</p>
          </div>

        ))}
      </div>
    </>
  );
};

export default QualityReportSpl;
