import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Switch,
  ListItem,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  FileUpload,
  getData,
  patchData,
  postData,
} from "../../webService/webService";
import INVOICEAPI from "src/API_Routes/InvoiceAPI/InvoiceAPI";
import { Col, Row } from "react-bootstrap";
import Invoicetemplate from "../templates/invoicetemplate";
import jsPDF from "jspdf";
import FILEROUTES from "src/API_Routes/File/file";
import { useDispatch } from "react-redux";
import { List } from "reactstrap";
import Invoice from "../templates/invoiceTemplateNew";

function numberToWords(num) {
  const belowTwenty = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

  function helper(num) {
    if (num === 0) return "";
    if (num < 20) return belowTwenty[num] + " ";
    if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
    return belowTwenty[Math.floor(num / 100)] + " Hundred " + helper(num % 100);
  }

  function convertIntegerPart(num) {
    if (num === 0) return "Zero";
    let words = "";
    let i = 0;
    while (num > 0) {
      if (num % 1000 !== 0) {
        words = helper(num % 1000) + thousands[i] + " " + words;
      }
      num = Math.floor(num / 1000);
      i++;
    }
    return words.trim();
  }

  function convertFractionalPart(fraction) {
    if (fraction === 0) return "";
    return helper(fraction) + "Paise";
  }

  // Split the number into integer and fractional parts
  const [integerPart, fractionalPart] = num.toString().split(".");
  const integerNumber = parseInt(integerPart, 10);
  const fractionalNumber = parseInt(
    fractionalPart ? fractionalPart.slice(0, 2) : "0",
    10
  );

  let result = convertIntegerPart(integerNumber);
  if (fractionalNumber > 0) {
    result += " Rupees and " + convertFractionalPart(fractionalNumber);
  }

  return result.trim();
}

function AddInvoice({
  basicClientDetail,
  outwardTable,
  outwardList,
  invData,
  billId,
  handleCloseInvoiceOpen,
  HandleOutward,
  invoiceNo,
  isIgsts
}) {
  const [rows, setRows] = useState([]);
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(false);
  const [confirmFor, setConfirmFor] = useState("");
  const [data, setData] = useState("");
  const [isIgst, setIsIgst] = useState(isIgsts);
  const [tablesData, setTablesData] = useState([]); // Initialize with one table's rows
  const [igstList, setIgstList] = useState({});
  // const [invoiceNo, setInvoiceNo] = useState(invoiceNo)

  const handleClose = () => {
    setOpen(false);
  };

  console.log(invData, "invData");

  console.log(rows, "rowsssssssss");

  console.log(isIgsts, "hdfhiushfushfduhu");




  useEffect(() => {

    const updatedTable = outwardTable.filter(row => row.pendingQuantity > 0).map((row) => {
      row.partId = row._id;
      row.invoiceQuantity = row.outwardQuantity;
      row.pendingQuantity = row.pendingQuantity;
      row.amount = (row.rate > 0) ? row.rate * row.outwardQuantity : 0;
      const cgst = Number(Math.round(row.amount * 0.06 * 100) / 100);
      const sgst = Number(Math.round(row.amount * 0.06 * 100) / 100);
      row.cgst = cgst;
      row.sgst = sgst;
      row.totalgst = row.cgst + row.sgst;
      row.outwardId = (row.outwardId) ? row.outwardId : billId;
      return row;
    });
    setRows(updatedTable);
  }, [])

  console.log(rows, "rowwwwwwwwwww");



  const handleInputChange = (rowIndex, event) => {
    const { name, value } = event.target;
    const newTablesData = [...rows];
    const row = { ...newTablesData[rowIndex] };

    if (name === "invoiceQuantity") {
      const outwardQuantity = Number(row.outwardQuantity ?? 0);
      const invoiceQuantity = Number(value ?? 0);

      if (invoiceQuantity > outwardQuantity) {
        toast.warn("Invoice quantity cannot exceed outward quantity");
        return; // Prevent the change
      }

      // Update invoiceQuantity and calculate pendingQuantity
      row.invoiceQuantity = invoiceQuantity;
      row.pendingQuantity = outwardQuantity - invoiceQuantity; // Calculate pendingQuantity
    } else {
      row[name] = value;
    }

    row.amount = (row.invoiceQuantity ?? 0) * (row.rate ?? 0);
    const cgst = Number(Math.round(row.amount * 0.06 * 100) / 100);
    const sgst = Number(Math.round(row.amount * 0.06 * 100) / 100);
    row.cgst = cgst;
    row.sgst = sgst;
    row.totalgst = row.cgst + row.sgst;
    newTablesData[rowIndex] = row;
    setRows(newTablesData);
  };

  const calculateTotalAmount = (index, key) => {
    if (index < 0 || index >= tablesData.length) {
      return 0; // Index out of bounds
    }
    const rows = tablesData[index];
    const total = rows.reduce(
      (total, row) => total + parseFloat(row.amount || 0),
      0
    );

    if (key == "total") {
      return total.toFixed(2);
    } else if (key == 'cgst') {
      if (igstList[index]) return 0;
      return (total * 0.06).toFixed(2);
    } else if (key == 'sgst') {
      if (igstList[index]) return 0;
      return (total * 0.06).toFixed(2);
    } else if (key == 'igst') {
      if (!igstList[index]) return 0;
      return (total * 0.12).toFixed(2);
    } else if (key == "grandtotal") {
      return (total + total * 0.12).toFixed(2);
    }
  };

  const handleAddTable = () => {
    setTablesData([...tablesData, rows.filter((item) => item.rate > 0)]);
    setRows((prevRows) => prevRows.filter((row) => row.rate == 0));
    setIsIgst(false);
  };

  // useEffect(() => {
  // Check the first two digits of the gstNo
  if (data.gstNo?.slice(0, 2) === "33") {
    debugger;
    setIsIgst(false); // Default to CGST & SGST
  } else if (data.gstNo) {
    setIsIgst(true); // Switch to IGST
  }
  // }, [data.gstNo]);

  const handleAddInvoice = () => {
    if (!rows.some((item) => item.amount > 0)) {
      toast.error("Atleast a invoice item need to be valid", {});
      return;
    }
    if ((outwardList.length > 1) && (!rows.every(item => item.amount > 0))) {
      toast.error("All items must be billed on bulk invoice", {});
      return;
    }

    setIgstList({ ...igstList, [Object.keys(igstList).length]: isIgst });
    setConfirmFor("invoiceCreate");
    setOpen(true);
  };

  const dispatch = useDispatch();

  const handleGeneratePdf = async () => {
    const pdfPromises = data.map((item, index) => {
      return new Promise((resolve, reject) => {
        const content = reportTemplateRefs.current[index];
        const scale = 0.8;

        const doc = new jsPDF({
          format: "a4",
          unit: "px",

        });
        doc.setFont("sans-serif", "normal");

        doc.html(content, {
          html2canvas: {
            scale: scale,
            width: 695,
          },
          callback: async function (pdfDoc) {
            try {
              const pdfOutput = pdfDoc.output("blob");
              // const formData = new FormData();
              // formData.append(
              //   "file",
              //   pdfOutput,
              //   `Final_inspection_quality_report${index}.pdf`
              // );

              // const uploadRes = await FileUpload(
              //   `${FILEROUTES.UPLOAD}${basicClientDetail._id}`,
              //   formData,
              //   dispatch
              // );

              // item.invoiceFileObject = uploadRes.data;
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          x: 15,
          y: 15,
          width: 1000,
        });
      });
    });

    try {
      await Promise.all(pdfPromises);
      // Now post data to the server
      const res = await postData(INVOICEAPI.POSTALLINVOICE, data, accessToken);
      handleCloseInvoiceOpen();
      HandleOutward();
    } catch (error) {
      console.error("Error:", error);
    }
  };


  console.log(data, "dataaaaaaaaaaa");

  // const today = new Date();
  // const yyyy = today.getFullYear();
  // let mm = today.getMonth() + 1; // Months start at 0!
  // let dd = today.getDate();

  // if (dd < 10) dd = "0" + dd;
  // if (mm < 10) mm = "0" + mm;

  // const formattedToday = dd + "/" + mm + "/" + yyyy;

  const now = new Date();

  // Get the ISO 8601 string representation of the date
  const isoString = now.toISOString();


  const handleSubmit = () => {

    // Get the current assessment year
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartMonth = 3; // April (0-based index)

    const assessmentYear =
      currentDate.getMonth() >= fiscalStartMonth
        ? `${currentYear}-${currentYear + 1}`
        : `${currentYear - 1}-${currentYear}`;

    const dataToSend = tablesData.map((tableData, index) => {
      const totalQuantity = tableData.reduce(
        (sum, row) => sum + (Number(row.invoiceQuantity) || 0),
        0
      );
      const totalAmount = tableData.reduce(
        (sum, row) => sum + (Number(row.amount) || 0),
        0
      );
      // const gTotal = (totalAmount + totalAmount * 0.12 + totalAmount * 0.06 + totalAmount * 0.06).toFixed(2);
      const gTotal = (totalAmount + totalAmount * 0.12).toFixed(2);
      return {
        invoiceNo: `USF/${assessmentYear}/${invoiceNo}`,
        outwardId: (outwardList.length > 1) ? outwardList.map(i => i._id) : [billId],
        date: isoString,
        orderNo: invData.orderno,
        clientId: basicClientDetail?._id,
        companyName: basicClientDetail?.companyname,
        branch: basicClientDetail?.branch,
        doorNo: basicClientDetail?.doorNo,
        streetName: basicClientDetail?.streetName,
        city: basicClientDetail?.city,
        pinCode: basicClientDetail?.pinCode,
        gstNo: basicClientDetail?.gstNo,
        igst: ((igstList[index]) ? (totalAmount * 0.12) : 0).toFixed(2),
        cgst: ((!igstList[index] ? totalAmount * 0.06 : 0).toFixed(2)),
        sgst: ((!igstList[index]) ? (totalAmount * 0.06) : 0).toFixed(2),
        grandTotal: gTotal,
        grandTotalGst: Number((!igstList[index] ? totalAmount * 0.06 : 0).toFixed(2)) * 2,
        grandTotalGstWords: numberToWords(Number((!igstList[index] ? totalAmount * 0.06 : 0).toFixed(2)) * 2),
        grandTotalInWords: numberToWords(gTotal),
        totalQuantity,
        totalAmount,
        invoiceData: tableData,
      };
    });

    setData(dataToSend);
    handleopen();
    return;
  };
  const [openDialog, setOpenDialog] = useState(false);

  const handleopen = () => {
    setOpenDialog(true);
  };

  const handleCloses = () => {
    setOpenDialog(false);
  };
  const reportTemplateRefs = useRef([]);

  const handlePrint = () => {
    // Get the combined HTML content from all refs
    const printContent = reportTemplateRefs.current
      .map((el) => el.innerHTML)
      .join("");

    const originalContent = document.body.innerHTML;

    // Create a temporary container for the print content
    document.body.innerHTML = printContent;

    // Trigger the print dialog
    window.print();

    // Restore the original content after printing
    document.body.innerHTML = originalContent;
    // window.location.reload();
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloses} maxWidth="lg" fullWidth>
        <DialogTitle>Invoice</DialogTitle>
        <DialogContent>
          {(data.length > 0) ? data.map((item, i) => (
            <div ref={(el) => (reportTemplateRefs.current[i] = el)} index={i} style={{ width: "50%" }}>
              <Invoice row={item} index={i}/>
            </div>
          )) : <></>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className="ml-8 btn-secondary"
            onClick={handleCloses}
          >
            Close
          </Button>
          {/* <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handlePrint();
            }}
            variant="contained"
          >
            Print
          </Button> */}
          <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handleGeneratePdf();
              handleClose();
            }}
            variant="contained"
          >
            View & Submit
          </Button>
        </DialogActions>
      </Dialog>
      {rows.length > 0 ?
        <Row>
          <Col lg={12}>
            <Table className="invoiceForm">
              <TableHead>
                <TableRow>
                  <TableCell>Part Name</TableCell>
                  <TableCell>Outward Quantity</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>CGST 6%</TableCell>
                  <TableCell>SGST 6%</TableCell>
                  <TableCell>IGST 12%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => (
                  (row.pendingQuantity > 0) ? <TableRow key={rowIndex}>
                    <TableCell>{row.partName}</TableCell>
                    <TableCell>{row.invoiceQuantity ?? 0}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        name="rate"
                        value={row.rate ?? 0}
                        onChange={(e) => handleInputChange(rowIndex, e)}
                      />
                    </TableCell>
                    <TableCell>{row.amount ?? 0}</TableCell>
                    <TableCell>{row.cgst ?? 0}</TableCell>
                    <TableCell>{row.sgst ?? 0}</TableCell>
                    <TableCell>{row.totalgst ?? 0}</TableCell>
                  </TableRow> : <></>
                ))}
              </TableBody>
            </Table>
          </Col>
          <Col lg={12} align={'right'}>
            {/* <strong>CGST & SGST<Switch inputProps={{ 'aria-label': 'controlled' }} onChange={() => setIsIgst(!isIgst)} />IGST</strong> */}
            <strong>
              CGST & SGST
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={isIgst}
                onChange={() => setIsIgst(!isIgst)} // Toggle isIgst state
              />
              IGST
            </strong>
            <Button variant="outlined" className='btn-secondary mx-3' onClick={handleCloseInvoiceOpen}>Cancel</Button>
            <Button variant="contained" className='ml-3 my-3 btn-primary' onClick={handleAddInvoice}>Add Invoice</Button>
          </Col>
        </Row>
        : <></>}
      {(tablesData.length > 0) ?
        <Row>
          <Col lg={12}>
            <Table className="m-0">
              <TableHead>
                <TableRow>
                  <TableCell className='table-cl'></TableCell>
                  <TableCell className='table-cl'>Part Name</TableCell>
                  <TableCell className='table-cl'>Invoice Quantity</TableCell>
                  <TableCell className='table-cl'>Rate</TableCell>
                  <TableCell className='table-cl' align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tablesData.map((tableData, tableIndex) =>
                  tableData.map((row, rowIndex) => (
                    ((row.invoiceQuantity > 0) && (row.rate > 0) && (row.amount > 0)) ? <>
                      <TableRow key={rowIndex} tablekey={tableIndex} >
                        {(rowIndex == 0) ? <TableCell rowSpan={tableData.length + 1}>{tableIndex + 1}</TableCell> : null}
                        <TableCell>{row.partName}</TableCell>
                        <TableCell>{row.invoiceQuantity}</TableCell>
                        <TableCell>{row.rate}</TableCell>
                        <TableCell align="right">{(row.amount ?? 0).toFixed(2)}</TableCell>
                      </TableRow>
                      {((rowIndex == tableData.length - 1) || (rowIndex == tableData.length)) ?
                        <>
                          <TableRow key={rowIndex}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                              <List>
                                <ListItem className="p-0 justify-content-between">
                                  <strong>Total Amount:</strong><div>{calculateTotalAmount(tableIndex, 'total')}</div>
                                </ListItem>
                                <ListItem className="p-0 justify-content-between">
                                  <strong>CGST@{(igstList[tableIndex]) ? 0 : 6}%:</strong><div>{calculateTotalAmount(tableIndex, 'cgst')}</div>
                                </ListItem>
                                <ListItem className="p-0 justify-content-between">
                                  <strong>SGST@{(igstList[tableIndex]) ? 0 : 6}%:</strong><div>{calculateTotalAmount(tableIndex, 'sgst')}</div>
                                </ListItem>
                                <ListItem className="p-0 justify-content-between">
                                  <strong>IGST@{(igstList[tableIndex]) ? 12 : 0}%:</strong><div>{calculateTotalAmount(tableIndex, 'igst')}</div>
                                </ListItem>
                                <ListItem className="p-0 justify-content-between">
                                  <strong>Grand Total:</strong><div>{calculateTotalAmount(tableIndex, 'grandtotal')}</div>
                                </ListItem>
                              </List>
                            </TableCell>
                          </TableRow>
                        </> : null}
                    </>
                      : null
                  ))
                )}
              </TableBody>
            </Table>
          </Col>
          <Col lg={12} align="right">
            <div className="mt-3">
              <Button variant="outlined" className='btn-secondary' onClick={handleCloseInvoiceOpen}>Cancel</Button>
              <Button disabled={(tablesData.length == 0) ? true : false} variant="contained" className='ml-8 btn-primary' onClick={handleSubmit}>Submit</Button>
            </div>
          </Col>
        </Row>
        : <></>}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {confirmFor == "invoiceCreate"
            ? "Create Invoice"
            : confirmFor == "cancelInvoice"
              ? "Cancel Invoices"
              : confirmFor == "submitInvoice"
                ? "Submit Invoices"
                : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmFor == "invoiceCreate"
              ? "Review and confirm to add a new table."
              : confirmFor == "cancelInvoice"
                ? "Are you sure want to cancel the current invoices"
                : confirmFor == "submitInvoice"
                  ? "Are you sure want to submit the invoices"
                  : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (confirmFor == "invoiceCreate") {
                handleAddTable();
              } else if (confirmFor == "cancelInvoice") {
                handleClose();
              } else if (confirmFor == "submitInvoice") {
                handleSubmit();
              }
              handleClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddInvoice;
