import React, { useEffect, useRef, useState } from "react";
import { getData } from "../../webService/webService";
import INVOICEAPI from "../../API_Routes/InvoiceAPI/InvoiceAPI";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Iconify from "../iconify/Iconify";
import useAllClients from "../CommonFunction/GetallClients";
import { toast, ToastContainer } from "react-toastify";
import totalInvoiceLogo from "../logo/ecommerce.png";
import totalAmountLogo from "../logo/invoiceIcon.png";
import Invoice from "../templates/invoicetemplate";

function InvoiceTable() {
  const accessToken = sessionStorage.getItem("Token");
  const [invoiceData, setinvoiceData] = useState();
  const [TotalItems, setTotalItems] = useState();
  const [TotalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [selectedInvoiceData, setselectedInvoiceData] = useState();
  const [openInvoice, setOpenInvoice] = useState(false);

  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reset, setReset] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [branch, setBranch] = useState();
  const [company, setCompany] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const role = sessionStorage.getItem('isAuthenticated');

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  //For Search Filter

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handleopen = () => {
    setOpenDialog(true);
  };

  const handleCloses = () => {
    setOpenDialog(false);
  };

  const reportTemplateRef = useRef(null);

  const handlePrint = () => {
    const printContent = reportTemplateRef.current;
    const originalContent = document.body.innerHTML;
    // Set the document's body to only include the printContent
    document.body.innerHTML = printContent.innerHTML;
    // Trigger the print dialog
    window.print();
    // Restore the original document content
    document.body.innerHTML = originalContent;
    // Reattach the React component to the DOM
    window.location.reload();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const AllInvoiceList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    try {
      const params = {
        page: page,
        limit: limit,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        companyName: companyName ? companyName : "",
        branch: branch ? branch : "",
      };
      const res = await getData(INVOICEAPI.GETALLINVOICE, params, accessToken);
      if (res.status === 200) {
        setTotalPages(res.data[0].totalPages);
        setTotalInvoiceAmount(res?.data?.[0]?.totalAmount);
        if (res.data?.[0]?.totalCount?.totalItems) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }

        setinvoiceData(res.data[0].paginatedResults);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    AllInvoiceList(currentPage);
  }, [currentPage, limit]);

  const handleSearch = () => {
    setCurrentPage(1);
    AllInvoiceList(1);
  };

  const removeData = () => {
    setStartDate();
    setEndDate();
    setCompanyName();
    setReset(true);
    setBranch();
    setCompany();
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllInvoiceList(1);
    }
  }, [reset]);

  // index based on currentPage
  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  //   search Filter
  const { clientDetails, totalItems, fetchAllClients } = useAllClients();
  useEffect(() => {
    if (showFilter) {
      fetchAllClients();
    }
  }, [showFilter]);

  const [selectedInvoiceArray, setSelectedInvoiceArray] = useState([])

  const handleviewInvoices = (data) => {
    // setCompanyName(companyName);
    const selectedinvoice = invoiceData?.find((i) => i._id === data);
    setSelectedInvoiceArray([selectedinvoice])
    handleopen(true);
  };

  // Function to handle company selection
  const handleCompanyChanges = (event) => {
    const companyName = event.target.value;
    const company = clientDetails?.find((c) => c._id === companyName);
    setCompanyName(company.companyname);
    setCompany(company._id);
    setBranch(company?.branch);
  };

  const handleviewInvoice = (data) => {
    const selectedinvoice = invoiceData?.find((i) => i._id === data);
    setselectedInvoiceData(selectedinvoice);
    setOpenInvoice(true);
  };
  const handleClose = () => {
    setOpenInvoice(false);
    setselectedInvoiceData();
  };

  console.log(selectedInvoiceArray, "selectedInvoiceArray");

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Invoice Report</DialogTitle>
        <DialogContent>
          <div ref={reportTemplateRef} style={{ width: "50%" }}>
            {
              selectedInvoiceArray &&
              <Invoice data={selectedInvoiceArray} />
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className="ml-8 btn-secondary"
            onClick={handleCloses}
          >
            Close
          </Button>
          <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handlePrint();
            }}
            variant="contained"
          >
            Print PDF
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openInvoice} onClose={handleClose} maxWidth={"xl"}>
        <DialogTitle>Invoice Details</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-cl color-dark p-3">
                    S.No
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    USF DC No
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    USF DC Date
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Part Name
                  </TableCell>
                  <TableCell className="table-cl color-dark">Part No</TableCell>
                  <TableCell className="table-cl color-dark">
                    Description
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Customer DC No
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Quantity
                  </TableCell>
                  <TableCell className="table-cl color-dark">Rate</TableCell>
                  <TableCell className="table-cl color-dark">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedInvoiceData &&
                  selectedInvoiceData?.invoiceData &&
                  selectedInvoiceData?.invoiceData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="p-3">
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={index + 1}
                          InputProps={{ readOnly: true }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.usfDCNo}
                          name="USF DC No"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="w-100"
                          type="date"
                          name="USF DC Date"
                          value={row?.usfDcDate?.split("T")[0]}
                          size="medium"
                          InputLabelProps={{ shrink: true }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.partName}
                          name="partName"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.partNo}
                          name="partNo"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.description}
                          name="description"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.customerDCNo}
                          name="dc"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.invoiceQuantity}
                          name="quantity"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.rate}
                          name="Rate"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={row.amount}
                          name="Amount"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">
              Total Qty: {selectedInvoiceData?.totalQuantity}
            </p>
            <p className="m-0">
              Total Amount: {selectedInvoiceData?.totalAmount}
            </p>
            {selectedInvoiceData?.igst ? (
              <p className="m-0">IGST@12%: {selectedInvoiceData?.igst}</p>
            ) : (
              <>
                <p className="m-0">CGST@6%: {selectedInvoiceData?.cgst}</p>
                <p className="m-0">SGST@6%: {selectedInvoiceData?.sgst}</p>
              </>
            )}
            <p className="m-0">
              Grand Total: {selectedInvoiceData?.grandTotal}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="btn-secondary"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <div className="d-flex justify-content-between align-items-center pt-3 pb-4">
        <div className="d-flex align-items-center ">
          <img src={totalInvoiceLogo} width="35px" />
          <p className="fw-bold mb-0 me-4 color-dark">
            Total Invoice : {TotalItems}
          </p>
          {role === 'Admin' ? (
            <>
              <img src={totalAmountLogo} width="30px" className="mr-2" />
              <p className="fw-bold mb-0 color-dark">
                Total Invoice Amount : {TotalInvoiceAmount.toFixed(2)}
              </p>
            </>
          ) : (
            <></>
          )}

        </div>

        <div className="d-flex col-6 justify-content-end align-items-center">
          {showFilter && (
            <span
              className="me-2 danger"
              style={{ color: "red", fontSize: "13px", cursor: "pointer" }}
              onClick={(e) => {
                toggleFilter();
                removeData();
              }}
            >
              Clear Filter
            </span>
          )}
          <IconButton
            className="p-12 ftrbkgd rounded"
            onClick={(e) => {
              toggleFilter();
              removeData();
            }}
          >
            {showFilter ? (
              <Iconify icon="ri:filter-off-fill" />
            ) : (
              <Iconify icon="material-symbols:filter-alt-sharp" />
            )}
          </IconButton>
        </div>
      </div>

      {showFilter && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: 500, margin: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className="w-100"
                    type="date"
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="w-100"
                    type="date"
                    label="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: startDate,
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <Grid item xs={3}>
              <FormControl sx={{ margin: 1, minWidth: 278 }} size="large" className="select">
                <InputLabel id="company-name-label">Company Name</InputLabel>
                <Select
                  labelId="company-name-label"
                  id="company-name-select"
                  label="Company Name"
                  value={company}
                  onChange={handleCompanyChanges}
                >
                  {clientDetails &&
                    clientDetails?.map((company) => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.companyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <div>
              <Button
                style={{ padding: "14px 40px !important" }}
                variant="outlined"
                className="ml-4 btn-secondary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        </>
      )}
      <TableContainer style={{ height: "333px", overflowY: "scroll" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="table-cl color-dark">S.No</TableCell>
              <TableCell className="table-cl color-dark">
                Company Name
              </TableCell>
              <TableCell className="table-cl color-dark">Invoice No</TableCell>
              <TableCell className="table-cl color-dark">
                Invoice Date
              </TableCell>
              <TableCell className="table-cl color-dark">
                Total Amount
              </TableCell>
              <TableCell className="table-cl color-dark">Total Qty</TableCell>
              <TableCell className="table-cl color-dark text-center">
                Invoice View
              </TableCell>
              <TableCell className="table-cl color-dark text-center">
                Download Invoice
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData &&
              invoiceData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell> {calculateIndex(index)}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}>{row.companyName}</TableCell>
                  {/* <TableCell>{row.usfDCNo}</TableCell> */}
                  <TableCell>{row.invoiceNo}</TableCell>
                  <TableCell>{row.updatedAt?.split("T")[0]}</TableCell>
                  <TableCell>{row.totalAmount}</TableCell>
                  <TableCell>{row.totalQuantity}</TableCell>
                  <TableCell align="center">
                    <Iconify
                      icon={"mdi:eye"}
                      sx={{ mr: 2 }}
                      style={{ cursor: "pointer", color: "#637381" }}
                      onClick={() => handleviewInvoice(row._id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Iconify icon={"bxs:file-pdf"}
                      onClick={() =>
                        handleviewInvoices(
                          row._id,
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}

            {invoiceData && invoiceData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex flex-wrap justify-content-end m-3">
        <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
          <Select
            labelId="limit-label"
            id="limit-select"
            value={limit}
            onChange={handleLimitChange}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={TotalItems}>All</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handlePrevPage} disabled={currentPage === 1}>
          <Iconify icon={"grommet-icons:previous"} />
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <Iconify icon={"grommet-icons:next"} />
        </Button>
      </div>
    </Paper>
  );
}

export default InvoiceTable;
