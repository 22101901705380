// component
import Iconify from "../../../components/iconify/Iconify";
import SvgColor from "../../../components/svg-color";
import customer from "../../../components/logo/rating.png";
import inward from "../../../components/logo/inbound (1).png";
import outward from "../../../components/logo/outbound.png";
import stockReport from "../../../components/logo/inventory.png";
import qualityReport from "../../../components/logo/quality.png";
import invoice from "../../../components/logo/invoiceIcon.png";
import purchaseInventory from "../../../components/logo/purchaseIcon.png"

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfigBiller = [
  // {
  //   title: "dashboard",
  //   path: "/biller/app",
  //   icon: <Iconify icon="ic:baseline-dashboard" />,
  // },
  {
    title: "All Customers",
    path: "/biller/allcustomers",
    // icon: <Iconify icon="pepicons-pop:people" />,
    icon: <img src={customer} width="50%" />
  },
  {
    title: "Delivery Challan",
    path: "/biller/challan",
    // icon: <Iconify icon="icon-park-twotone:express-delivery" />,
    icon: <img src={inward} width="50%" />
  },
  {
    title: "Outward List",
    path: "/biller/Outward",
    // icon: <Iconify icon="material-symbols:arrow-outward-rounded" />,
    icon: <img src={outward} width="50%" />
  },
  {
    title: "Stock Reports",
    path: "/biller/stockReport",
    // icon: <Iconify icon="bxs:report" />,
    icon: <img src={stockReport} width="47%" />
  },
  {
    title: "Quality Reports",
    path: "/biller/quality-report",
    // icon: <Iconify icon="icon-park-solid:sales-report" />,
    icon: <img src={qualityReport} width="47%" />
  },
  {
    title: "Invoices",
    path: "/biller/invoices",
    // icon: <Iconify icon="ph:invoice-fill" />,
    icon: <img src={invoice} width="47%" />
  },
  // {
  //   title: "Purchase Inventory",
  //   path: "/biller/purchase_inventory",
  //   icon: <Iconify icon="material-symbols-light:inventory" />,
  // },
];

export default navConfigBiller;
