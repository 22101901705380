import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Grid,
  TableHead,
  TableRow,
  TableCell,
  Table,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import "../Inward/Addinward.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import INWARDAPI from "src/API_Routes/InwardAPI/InwardAPI";
import {
  FileUpload,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import FILEROUTES from "src/API_Routes/File/file";
import Iconify from "../iconify";
import { setALLINWARDLIST } from "src/redux/Slices/AllInwardSlices";
import API_URL from "src/utils/url";
import { setSpecificClientsDetails } from "src/redux/Slices/AllClientSlices";
import useFetchData from "../GetAllData/GetAllData";
import { number } from "prop-types";

function AddInwardchallan({
  HandleAddInward,
  action,
  limit,
  currentPage,
  setTotalItems,
}) {
  const [rows, setRows] = useState([
    {
      id: 1,
      partName: "",
      partNO: "",
      process: "",
      Units: "None",
      SACNo: 998898,
      Quantity: 0,
    },
  ]);
  const accessToken = sessionStorage.getItem("Token");
  const [selectedCompany, setSelectedCompany] = useState(null);
  console.log(selectedCompany, "selectedCompany");

  const ALLCLIENTSLIST = useSelector(
    (state) => state?.ALLCLIENTSLIST?.AllClientsList?.results
  );
  const [productDropdown, setProductDropdown] = useState({});
  const dispatch = useDispatch();
  const [dcNo, setDcNo] = useState();
  const [dcDate, setDcDate] = useState();
  const [inwardfileupload, setInwardFileUpload] = useState();
  const [commitmentDate, setCommitmentDate] = useState();
  const [commitmentTime, setCommitmentTime] = useState();
  const [respPerson, setRespPerson] = useState();
  const [assesmentYear, setAssesmentYear] = useState();
  const [clientDetails, setClientDetails] = useState();
  const ALLINWARDLIST = useSelector(
    (state) => state?.ALLINWARDLIST?.AllInwardList[0]?.paginatedResults
  );
  const specificInwardDetails = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.inwardDeliveryNoteChallan
        ?.inwardTable
  );
  const specificInwardChellanDetails = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.inwardDeliveryNoteChallan
  );
  const specificClientDetails = useSelector(
    (state) => state?.ALLCLIENTSLIST?.specificClientsList
  );
  const INWARDTABLELIST = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.inwardDeliveryNoteChallan
        ?.inwardTable
  );
  const specificInwardChellan = useSelector(
    (state) => state?.ALLINWARDLIST?.specificInwardList
  );
  const URL = "https://api.usf.digitaltriumph.co.in/";

  console.log(specificInwardChellan, "............");

  const calculateGrandTotal = (rows) => {
    return rows?.reduce((sum, row) => sum + parseFloat(row.Quantity || 0), 0);
  };

  const [grandTotal, setGrandTotal] = useState(calculateGrandTotal(rows));

  useEffect(() => {
    if (INWARDTABLELIST) {
      setRows(INWARDTABLELIST);
    }
  }, [INWARDTABLELIST]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );

    setRows(updatedRows);

    if (name === "Quantity") {
      setGrandTotal(calculateGrandTotal(updatedRows));
    }
  };

  useEffect(() => {
    setGrandTotal(calculateGrandTotal(rows));
  }, [rows]);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        partName: "",
        partNO: "",
        process: "",
        Units: "NOS",
        SACNo: 998898,
        Quantity: "",
      },
    ]);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadRes = await FileUpload(
      `${FILEROUTES.UPLOAD}${selectedCompany?._id}`,
      formData,
      dispatch
    );

    setInwardFileUpload(uploadRes.data);
  };

  const AllInwardList = async () => {
    const params = {
      page: currentPage,
      limit: limit,
    };
    try {
      const res = await getData(INWARDAPI.GETALLBILL, params, accessToken);
      if (res.status === 200) {
        if (res?.data?.[0]?.totalCount?.totalItems) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }
        dispatch(setALLINWARDLIST(res.data));
        // setTotalPages(res.data[0].totalPages);
        console.log(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const currentYear = new Date().getFullYear();
  // const [dcYear1, setDcYear1] = useState(`${currentYear}-01-01`);
  // const [dcYear2, setDcYear2] = useState(`${currentYear + 1}-01-01`);

  // // Generate a list of years (e.g., from current year to next year)
  // const generateYearOptions = () => {
  //   const years = [];
  //   for (let year = currentYear; year <= currentYear + 1; year++) {
  //     years.push(year);
  //   }
  //   return years;
  // };

  // // Generate a list of years for the end year based on the selected start year
  // const generateEndYearOptions = (startYear) => {
  //   const years = [];
  //   for (let year = startYear; year <= startYear + 1; year++) {
  //     years.push(year);
  //   }
  //   return years;
  // };

  // const handleYearChange1 = (e) => {
  //   const selectedYear = e.target.value;
  //   const formattedDate = `${selectedYear}-01-01`;
  //   setDcYear1(formattedDate);
  // };

  // const handleYearChange2 = (e) => {
  //   const selectedYear = e.target.value;
  //   const formattedDate = `${selectedYear}-01-01`;
  //   setDcYear2(formattedDate);
  // };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const isBeforeApril = currentDate.getMonth() < 3; // January to March

  // Determine base year dynamically
  const baseYear = isBeforeApril ? currentYear - 1 : currentYear;

  const [dcYear1, setDcYear1] = useState(`${baseYear}-01-01`);
  const [dcYear2, setDcYear2] = useState(`${baseYear + 1}-01-01`);

  // Generate a list of years (e.g., from base year to next year)
  const generateYearOptions = () => {
    return [baseYear, baseYear + 1];
  };

  // Generate a list of years for the end year based on the selected start year
  const generateEndYearOptions = (startYear) => {
    return [startYear, startYear + 1];
  };

  const handleYearChange1 = (e) => {
    const selectedYear = e.target.value;
    setDcYear1(`${selectedYear}-01-01`);
  };

  const handleYearChange2 = (e) => {
    const selectedYear = e.target.value;
    setDcYear2(`${selectedYear}-01-01`);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(rows);
    if (!selectedCompany) {
      toast.error("Company Name is required");
      return;
    }
    if (!dcNo) {
      toast.error("Customer DC No is required");
      return;
    }
    if (!dcDate) {
      toast.error("Customer DC Date is required");
      return;
    }

    const uniqueEntries = new Set();

    for (const row of rows) {
      if (!row.partName) {
        toast.error(`Part Name is required`);
        return;
      }
      if (!row.partNO) {
        toast.error(`Part No is required `);
        return;
      }
      if (!row.process) {
        toast.error(`Process is required`);
        return;
      }
      if (!row.Units) {
        toast.error(`Units is required`);
        return;
      }
      if (!row.Quantity) {
        toast.error(`Quantity is required`);
        return;
      }

      // // Create a unique key based on partName, partNO, and process
      // const key = `${row.partName}-${row.partNO}-${row.process}`;

      // if (uniqueEntries.has(key)) {
      //   toast.error(
      //     `Duplicate entry found: Part Name ${row.partName}, Part No ${row.partNO}, Process ${row.process}`
      //   );
      //   return;
      // }

      // // Add the unique key to the Set
      // uniqueEntries.add(key);
    }

    // for (const row of rows) {
    //   if (!row.partName) {
    //     toast.error(`Part Name is required for row ${row.id}`);
    //     return;
    //   }
    //   if (!row.partNO) {
    //     toast.error(`Part No is required for row ${row.id}`);
    //     return;
    //   }
    //   if (!row.process) {
    //     toast.error(`Process is required for row ${row.id}`);
    //     return;
    //   }
    //   if (!row.Units) {
    //     toast.error(`Units is required for row ${row.id}`);
    //     return;
    //   }
    //   if (!row.Quantity) {
    //     toast.error(`Quantity is required for row ${row.id}`);
    //     return;
    //   }
    // }

    const paramsArray = {
      clientId: selectedCompany?._id,
      inwardDeliveryNoteChallan: {
        companyName: selectedCompany?.companyname,
        branch: selectedCompany?.branch,
        dcNo: dcNo,
        dcDate: dcDate?.split("T")[0],
        assesmentStartYear: dcYear1,
        assesmentEndYear: dcYear2,
        abbrevation: "Cleaning",
        totalValue: grandTotal,
        grandQuantity: grandTotal,
        inwardDeliveryChallanFileObject: inwardfileupload,
        inwardTable: rows
          ?.filter((row) => row.Quantity != 0)
          ?.map((row) => ({
            partName: row.partName,
            partNo: row.partNO,
            process: row.process,
            units: row.Units,
            sacNo: "998898",
            quantity: row.Quantity,
            initialQuantity: row.Quantity,
          })),
      },
    };

    console.log(paramsArray, "paramsArray");

    if (action === "Add") {
      await postData(INWARDAPI.ADDINWARD, paramsArray, accessToken)
        .then((res) => {
          console.log(res);
          AllInwardList();
          HandleAddInward(ALLINWARDLIST);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      await patchData(
        `bill/${specificInwardChellan._id}/client/${specificInwardChellan.clientId}`,
        paramsArray,
        accessToken
      )
        .then((res) => {
          AllInwardList();
          HandleAddInward(ALLINWARDLIST);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    if (action === "Add") {
      dispatch(setSpecificClientsDetails(""));
      setSelectedCompany("");
    }
  }, [action]);

  const [AllClient, allClientTotal, setAllClientTotal] = useFetchData(
    CLIENTAPI.GETCLIENTS,
    accessToken
  );

  console.log(AllClient, "allclient");
  useEffect(() => {
    if (allClientTotal > 1000) setAllClientTotal(allClientTotal);
  }, [allClientTotal]);

  // const AllClientsList = async (page) => {
  //     const params = {
  //         page: page,
  //         limit: null,
  //     };
  //     try {
  //         const res = await getData(
  //             CLIENTAPI.GETCLIENTS,
  //             params,
  //             accessToken
  //         );
  //         if (res.status === 200) {
  //             setClientDetails(res.data.results)

  //             console.log(ALLCLIENTSLIST, "Allclientslist")
  //         }
  //     } catch (error) {
  //         console.error("Error:", error);
  //     }
  // };

  console.log(clientDetails, "clientDetails");

  // Function to handle company selection
  const handleChange = (event) => {
    debugger
    const companyName = event.target.value;
    const company = AllClient?.find((c) => c._id === companyName);
    console.log(company, "company");
    setSelectedCompany(company);
  };

  const handlelist = (event, index) => {
    debugger
    console.log(selectedCompany, "selectedcompanyname");
    const partNo = event.target.value;
    const process = selectedCompany?.product.find(
      (c) => c.partNo === partNo
    );
    console.log(process, "process");
    const newProductDropdowns = { ...productDropdown, [index]: process };
    setProductDropdown(newProductDropdowns);

    const newRows = [...rows];
    newRows[index].partName = process?.partName || '';
    newRows[index].partNO = partNo;
    newRows[index].process = process?.productName || "";
    setRows(newRows);
  };

  console.log(specificInwardDetails, "specificinwarddetails");

  console.log(productDropdown, "sdassssssssss");

  console.log(rows, "rowsssssss");

  useEffect(() => {
    debugger;
    if (action === "Edit" || action === "View") {
      if (
        specificInwardDetails ||
        specificClientDetails ||
        specificInwardChellanDetails
      ) {
        setDcNo(specificInwardChellanDetails?.dcNo);
        setDcDate(specificInwardChellanDetails?.dcDate?.split("T")[0]);
        setCommitmentDate(
          specificInwardChellanDetails?.commitmentDate?.split("T")[0]
        );
        setRespPerson(specificInwardChellanDetails?.respPerson);
        setAssesmentYear(
          specificInwardChellanDetails?.assesmentYear?.split("-")[0]
        );
        const rows = specificInwardDetails?.map((item, index) => ({
          id: index + 1, // Assuming index starts from 0, adjust if necessary
          partName: item.partName,
          partNO: item.partNo,
          process: item.process,
          Units: item.units,
          SACNo: item.sacNo,
          Quantity: item.quantity,
        }));
        setRows(rows); // Set rows array
        const company = AllClient?.find(
          (c) => c._id === specificClientDetails?._id
        );
        setSelectedCompany(company);

        // Update productDropdown for Edit mode
        const initialProductDropdown = {};
        rows?.forEach((row, index) => {
          const partDetail = company?.product?.find(
            (p) => p.partNo === row.partNO
          );
          initialProductDropdown[index] = partDetail;
        });
        setProductDropdown(initialProductDropdown);
        console.log(initialProductDropdown, 'initialProductDropdowninitialProductDropdowninitialProductDropdowninitialProductDropdown');
        
      }
    } else {
      // Reset to initial state if not editing or viewing
      setRows([
        {
          id: 1,
          partName: "",
          partNO: "",
          process: "",
          Units: "",
          SACNo: "",
          SCPONO: "",
          SCPODate: "",
          Quantity: "",
        },
      ]);
      setDcNo("");
      setDcDate("");
      setCommitmentDate("");
      setCommitmentTime("");
      setRespPerson("");
      setAssesmentYear("");
    }
  }, [
    action,
    specificInwardDetails,
    specificClientDetails,
    specificInwardChellanDetails,
    AllClient,
  ]);

  useEffect(() => {
    if (selectedCompany) {
      if (action === "Add") {
        setDcDate("");
        setDcNo("");
      }
      handleChange({ target: { value: selectedCompany._id } });
    }
  }, [selectedCompany]);

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  return (
    <div className="rowdetails card p-4">
      <div className="component-title">
        <h5>{action} Inward</h5>
      </div>
      <div className="mt-2">
        <Grid container spacing={4} className="mb-5">
          <Grid item xs={3}>
            <FormControl
              sx={{ m: 1, Width: 100 }}
              size="large"
              className="m-0 w-100 select"
            >
              <InputLabel id="demo-select-small-label">Company Name</InputLabel>
              <Select
                labelId="demo-select-large-label"
                id="demo-select-large"
                value={
                  selectedCompany?._id ||
                  specificClientDetails?.companyname ||
                  ""
                }
                InputProps={{
                  readOnly: action === "View",
                }}
                label="Company Name"
                onChange={handleChange}
              >
                {AllClient &&
                  AllClient.map((company) => (
                    <MenuItem key={company._id} value={company._id}>
                      {company.companyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedCompany && selectedCompany?.companyname === "USUI SUSIRA INTERNATIONAL PVT LTD" ? (
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                className="placeholder-glow"
                label="Unit"
                value={
                  selectedCompany?.branch || specificClientDetails?.branch || ""
                }
                variant="outlined"
                sx={{ borderRadius: "15px", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

          ) : (
            <></>
          )}

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              className="placeholder-glow"
              label="Email Address"
              value={
                selectedCompany?.email || specificClientDetails?.email || ""
              }
              variant="outlined"
              sx={{ borderRadius: "15px", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Mobile number"
              value={
                selectedCompany?.phoneNumber ||
                specificClientDetails?.phoneNumber ||
                ""
              }
              variant="outlined"
              sx={{ borderRadius: "15px", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="GST No"
              value={
                selectedCompany?.gstNo || specificClientDetails?.gstNo || ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              sx={{ borderRadius: "15px", width: "100%" }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              className="w-100"
              select
              label="AssesmentStartYear"
              value={dcYear1.substring(0, 4)} // Display only the year part
              onChange={handleYearChange1}
              size="medium"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: action === "View",
              }}
            >
              {generateYearOptions().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              select
              label="AssesmentEndYear"
              value={dcYear2.substring(0, 4)} // Display only the year part
              onChange={handleYearChange2}
              size="medium"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: action === "View",
              }}
            >
              {generateEndYearOptions(parseInt(dcYear1.substring(0, 4))).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Customer DC No *"
              value={dcNo}
              name="DCNO"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: action === "View",
              }}
              sx={{ borderRadius: "15px", width: "100%" }}
              onChange={(e) => setDcNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              type="date"
              name="DCDate"
              label="Customer DCDate *"
              value={dcDate}
              min={getTodayDate()}
              size="medium"
              InputProps={{
                readOnly: action === "View",
              }}
              onChange={(e) => setDcDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Table className="w-100">
        <TableHead className="table w-100">
          <TableRow>
            <TableCell className="table-cl color-dark">S.No</TableCell>
            <TableCell className="table-cl color-dark">
              Part Name / Part No / Process
            </TableCell>

            <TableCell className="table-cl color-dark">Quantity</TableCell>
            <TableCell className="table-cl color-dark">Units</TableCell>
            {/* <TableCell className="table-cl color-dark">SAC</TableCell> */}
            <TableCell className="table-cl color-dark text-center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows?.map((row, index) => (
              <TableRow
                key={index}
                className={row.length === 0 ? "placeholder-glow" : ""}
              >
                <TableCell
                  className={row.length === 0 ? "placeholder" : ""}
                  style={{ width: "10%" }}
                >
                  <TextField
                    className="w-100"
                    fullWidth
                    variant="outlined"
                    value={index + 1}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>

                <TableCell
                  className={row.length === 0 ? "placeholder" : ""}
                  style={{ width: "45%" }}
                >
                  <Select
                    fullWidth
                    variant="outlined"
                    name="partName"
                    className="w-100"
                    value={row.partNO}
                    onChange={(e) => {
                      handlelist(e, index);
                      const selectedProduct = selectedCompany?.product.find(
                        (product) => product.partNo === e.target.value
                      );
                      handleInputChange(index, e);
                      handleInputChange(index, {
                        target: {
                          name: "partNO",
                          value: selectedProduct.partNo,
                        },
                      });
                      handleInputChange(index, {
                        target: {
                          name: "process",
                          value: selectedProduct.productName,
                        },
                      });
                    }}
                    disabled={action === "View"}
                  >
                    {selectedCompany?.product?.map((product, i) => (
                      <MenuItem key={i} value={product.partNo}>
                        {product.partName} / {product.partNo} /{" "}
                        {product.productName}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell style={{ width: "15%" }}>
                  <TextField
                    variant="outlined"
                    name="Quantity"
                    type="number"
                    className="w-100"
                    value={row.Quantity}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: action === "View",
                    }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </TableCell>

                <TableCell style={{ width: "12%" }}>
                  <Select
                    fullWidth
                    variant="outlined"
                    name="Units"
                    className="w-100"
                    value={row.Units}
                    InputProps={{
                      readOnly: action === "View",
                    }}
                    onChange={(e) => handleInputChange(index, e)}
                    disabled={action === "View"}
                  >
                    <MenuItem value="NOS">NOS</MenuItem>
                    <MenuItem value="KG">KG</MenuItem>
                  </Select>
                </TableCell>
                {/* <TableCell style={{ width: "12%" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="SACNo"
                    type="number"
                    value={998898}
                    className="w-100"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </TableCell> */}

                <TableCell style={{ width: "18%" }} className="text-center">
                  <IconButton
                    onClick={() => handleDeleteRow(index)}
                    disabled={action === "View"}
                  >
                    <Iconify icon={"eva:trash-2-outline"} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={4} align="right">
              Grand Total
            </TableCell>
            <TableCell>{grandTotal}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className="d-flex justify-content-between mt-3">
        <div className="AddButton d-flex align-items-center">
          {action !== "View" && (
            <>
              <Button
                variant="contained"
                className="btn-primary"
                onClick={addRow}
              >
                + Add Row
              </Button>
              <div
                className="btn-primary ml-8"
                style={{ marginLeft: "12px", borderRadius: "6px" }}
              >
                <label
                  htmlFor="file-upload"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Upload File
                </label>
                <input
                  id="file-upload"
                  style={{ display: "none" }}
                  type="file"
                  InputProps={{
                    readOnly: action === "View",
                  }}
                  onChange={handleFileUpload}
                />
              </div>
            </>
          )}
          {inwardfileupload
            ? (action === "Add" || action === "Edit") &&
            inwardfileupload && (
              <a
                style={{ marginLeft: "12px", textDecoration: "none" }}
                href={`${URL}/${inwardfileupload.uri}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {inwardfileupload?.originalname}
              </a>
            )
            : (action === "View" || action === "Edit") &&
            specificInwardChellanDetails?.inwardDeliveryChallanFileObject && (
              <a
                style={{ marginLeft: "12px", textDecoration: "none" }}
                href={`${URL}/${specificInwardChellanDetails?.inwardDeliveryChallanFileObject?.uri}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {
                  specificInwardChellanDetails
                    ?.inwardDeliveryChallanFileObject?.originalname
                }
              </a>
            )}
        </div>
        <div>
          <Button
            variant="outlined"
            onClick={HandleAddInward}
            className="btn-secondary"
          >
            BACK
          </Button>
          {action === "Add" && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="ml-8 btn-primary"
            >
              SUBMIT
            </Button>
          )}
          {action === "Edit" && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="ml-8 btn-primary"
            >
              UPDATE
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddInwardchallan;
